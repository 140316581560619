import React from 'react';
import { Flex } from 'rebass';
import { ThemeConsumer } from 'styled-components';
import Container from './Container';
import Paragraph from './Paragraph';
import Section, { Props } from './Section';

import aboutSectionBackgroundSrc from '../assets/about-section-background.svg';

const AboutSection = (props: Props) => (
  <ThemeConsumer>
    {theme => (
      <Section
        {...props}
        bg={[null, null, 'white']}
        py={[null, null, 3, 5]}
        css={`
          @media (min-width: ${theme.breakpoints[1]}px) {
            /*background-image: ${`url(${aboutSectionBackgroundSrc});`};*/
            background-size: 100% 100%;
          }
        `}
      >
        <Container
          as={Flex}
          flexDirection={['column', null, 'row']}
          alignItems="center"
          px={null}
        >
          <Container flex={1} my={3}>
            <Paragraph>
              Thank you for visiting my site and viewing my paintings. I was
              born in Darwen, Lancashire, part of the post war baby boom. I have
              lived in Glastonbury, Somerset for the last 25 years. I gained my
              degree in fine art at Preston Polytechnic (now the University of
              Central Lancashire).
            </Paragraph>

            <Paragraph>
              I began painting again recently - the long break seems to have
              cleansed my palette of 'overtly painterly concerns' - and I now
              just paint the sort of pictures that satisfy me. None of my
              paintings are autobiographical, but they do explore the past and
              memory. As I get older I am preoccupied with memories and how
              those memories are so tangled with the common cultural references
              of my childhood - the comics I read, the films I saw the
              television I watched. I'm fascinated by half remembered tastes,
              smells, people, shared experiences, the strangeness of the grown
              up world. I like to use bold colours - like the covers of my old
              comic annuals, large areas of flat colour with strong lines to
              define shape and weight, like the characters in the Beano, Topper
              and the Eagle. I use similar techniques in my portrait work -
              people as we remember them as much as how they outwardly appear.
              Most paintings are for sale, as an original canvas, or as a
              limited edition giclee prints.
            </Paragraph>

            <Paragraph>
              Please keep looking, I'm investigating older and older memories
              all the time...
            </Paragraph>

            <Paragraph>Frank Harwood</Paragraph>

            <Paragraph>
              'The past is a foreign country: they do things differently there.'
              L.P. Hartley - The Go-Between.
            </Paragraph>
          </Container>
        </Container>
      </Section>
    )}
  </ThemeConsumer>
);

export default AboutSection;
